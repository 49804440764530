.element-active{
    border: 1px solid #eee;
}

.toiler-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 400,
    'GRAD' 0,
    'opsz' 48
  }

  @media (width>=800px){
    .only-mobile{
        display: none !important;
    }
  }

  @media (width<800px){
    .only-desktop{
        display: none !important;
    }
  }

  .display-none{
    display: none !important;
  }